.#{$prefix}_m_services{
    background-color: $firefly;
    color: $wild-sand;
    padding-bottom: 8rem;
    &__introduction {
        margin-top: 19.6rem;
        margin-bottom: 9rem;

        &__title {
            font-family: $font-archivo;
            font-weight: 700;
            font-size: 3.2rem;
            line-height: 3.4rem;
            margin-bottom: 2.2rem;
        }

        &__description {
            font-family: $font-archivo;
            font-weight: 400;
            font-size: 2.4rem;
            line-height: 3.2rem;
            //height: 100%;
            padding-bottom: 4.4rem;
        }
    }

    &__services_list {
        //padding-bottom: 5rem;

        &__item {
            list-style: none;
            padding-bottom: 3rem;
            min-height: 38rem;

            &__title {
                color: $sunflower;
                line-height: 4.9rem;
                font-size: 4.8rem;
                font-weight: 500;
                font-style: normal;
                font-family: $font-archivo;
                margin-bottom: 4rem;
            }
            &__description {
                font-family: $font-archivo;
                font-weight: 500;
                font-size: 2.4rem;
                line-height: 3.6rem;
            }
        }
    }
    @include media(">=tablet") {   
        &__introduction {
            &__description { 
                max-width: 46vw;
            }
        } 
    } 
}