@font-face {
    font-family : Archivo;
    src         : url("/assets/fonts/Archivo-Regular.ttf");
  }
.#{$prefix}_m{   
    &__href{
        color:$black-pearl;
    } 
    &__href:hover{
        color:$black-pearl;
    } 
    &__row{
        padding-left  : 3rem;
        padding-right : 3rem;    
        
    }
    &__top-row-block {
        width: 80vw;
        padding-left:4rem;
        padding-right:1rem;
    }
    @include media(">=tablet") {    
        &__row{
            padding-left  : 20rem;
            padding-right : 20rem;  
        }        
        &__top-row-block {
            padding-left:16rem;
            padding-right:16rem; 
        }
        
    }    
    
    


}



