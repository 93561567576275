.#{$prefix}_m_partners{ 
       
    &__row{    
        padding-bottom : 8rem;
        padding-top    : 3rem;
    }
    &__title{
        margin-top    : 10rem;
        margin-bottom : 5rem;
        font-weight: bolder;
    }
    &--plus_2,
    &--plus_1,
    &__partner{
        width          : 140px;
        height         : 126px;
        display        : flex;
        flex-direction : column;
        object-fit     : contain; 
        ;       
    }
    
    
    &__ico-break-line{
        width  : 100%;
        height : 0px;                 
    }    
    
    &__full_partners{
        width  : 917px;
        height : 308px;
    }
    &__container{
        display        : flex;                
        flex-direction : row;    
        align-content  : flex-start;
        flex-wrap      : wrap;        
    }
    
    @include media("<=tablet") {
        &__ico-break-line{            
            display : none;            
        }
    }
    @include media(">=tablet") {   
        &--plus_1{         
            max-width : 19rem;         
        }
        &--plus_2{            
            max-width : 18.1rem;            
        } 
    }
    @include media(">=desktop") {
        &__first_row{
            margin-left:-42px;
        }    
        &__second_row{
            margin-left:-6px;
        }
        
        &__partner{            
            width          : 11vw;                    
            height         : auto;
            display        : flex;
            flex-direction : column;
            object-fit     : contain;        
        }
        &--plus_1{
            //min-width  : 16vw;   
            margin-left: 4vw;            
        }
        &--plus_2{
            //min-width  : 18vw;
            margin-left: 3vw;            
        }
        &__row{    
            //padding-left     : 16rem;
            padding-right    : 1rem;
        }
    }



}
