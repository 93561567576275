.#{$prefix}_m_contact-us{
    font-family : 'Archivo';
    width       : 100vw;    
    height      : 30rem;
    left        : 0;    
    background  : $sunflower;
    padding-top : 7.5rem;
    &-paragraph{        
        font-style  : normal;
        font-weight : 500;
        font-size   : 1.8rem;
        line-height : 2.4rem;        
        color       : $black-pearl;
        display     : block;
        width       : 100%;
    }
    &-title{
        font-family  : 'Archivo';
        font-style   : normal;
        font-weight  : 700;
        font-size    : 4.8rem;
        line-height  : 6.4rem;        
        color        : $wild-sand;
        display      : block;
    }
}
