.#{$prefix}_m_header {

    &__logo {
        background-color: $black-pearl;
        color: $wild-sand;
        width: 12.9rem;
        height: 4.5rem;
        text-align: center;
        font-size: 2.2rem;
        font-family: $font-archivo;
        font-weight: 700;
        margin-top: 6.4rem;
        padding-top: .5rem;
        margin-left: 0; 
 
    }
    &__title {
        font-family: $font-archivo;
        font-style: normal;
        font-weight: 700;
        line-height: 2rem;
        font-size: 9.6rem;
        color: $firefly;
        display: flex;
        margin-bottom: 4.2rem;
        margin-top: 17.3rem;;

    }

    &__introduction {
        font-size: 4.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 6.4rem;
        color: $firefly;
        margin-bottom: 9.4rem;
    }

    @include media(">=tablet") {    
        &__logo {
            margin-left: 5.3rem;
        }
    }   
}