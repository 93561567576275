#footer,
#contact_us,
#partners,
#services-intro,
#services-lists,
#fade-header{        
    h1,img,p,.column {
        opacity            : 0; 
        -webkit-transition : all 1s ease-in-out;
        -moz-transition    : all 1s ease-in-out;
        -ms-transition     : all 1s ease-in-out;
        -o-transition      : all 1s ease-in-out;
        transition         : all 1s ease-in-out;
    }
}
#footer.visible,
#contact_us.visible,
#partners.visible,
#services-intro.visible,
#services-lists.visible,
#fade-header.visible{
    h1,img,p,.column {
        opacity            : 1;
    }      
}